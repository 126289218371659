import { CustomTableHeader, Medications } from '@/@types';
import { Button, cn } from '@lib-atria/ui-toolkit';
import React, { useCallback } from 'react';
import { AtriaIcon } from '../icons';
import { TextLayout } from '../text';
import { MedicationsTable } from './';

type MedicationsTableAdminProps = {
  medications: Medications.Response;
  tableHeaders: CustomTableHeader[];
  isActionEnabled: boolean;
};

type InfoSectionProps = {
  title: string;
  description: string;
  buttonLabels: string[];
  buttonLinks: string[];
};

type AdminSectionHeaderProps = {
  title: string;
  subtitle: string;
};

export function MedicationsTableAdmin({
  medications,
  tableHeaders,
  isActionEnabled,
}: MedicationsTableAdminProps) {
  return (
    <div className='bg-neue-kelp-500 text-white lg:w-[calc(100vw-282px)] w-screen lg:-ml-[96px] md:-ml-[96px] -ml-8 -mb-20 inset-x-0 md:px-24 px-8 py-8 font-body h-full mt-8'>
      <AdminSectionHeader
        title='Admin section'
        subtitle='This section is not visible to the member'
      />
      <InfoSection
        title='Shown to the member'
        description='These medications do appear in the member’s portal.'
        buttonLabels={[]}
        buttonLinks={[]}
      />
      <MedicationsTable
        medications={medications.list}
        tableHeaders={tableHeaders}
        isActionEnabled={isActionEnabled}
      />
      <div className='md:h-[124px] h-10' />
      <InfoSection
        title='Hidden from the member'
        description='These medications are hidden because they were not entered or prescribed, or were intentionally removed, by Atria staff. If these medications should be shown to the member, they will need to be updated in the member’s medication list in Athena.'
        buttonLabels={['Read instructions', 'Watch video tutorial']}
        buttonLinks={[
          'https://docs.google.com/document/d/1VtiPyW3kmiFwHEsl9jlYSgUSsvmW7Hby0ZzUb-tfKlI/edit#bookmark=id.nolefb1eq2lv',
          'https://www.loom.com/share/5e5283f0e345404b9b99bc2b88c7d25a?sid=19ffff27-e613-48ab-8bf5-697cc9e2b274',
        ]}
      />
      <MedicationsTable
        medications={medications.excludedItems!}
        tableHeaders={tableHeaders}
        isActionEnabled={isActionEnabled}
        messageEmpty='No medications are hidden from the member'
      />
    </div>
  );
}

const AdminSectionHeader = ({ title, subtitle }: AdminSectionHeaderProps) => (
  <div className='flex justify-between items-center md:pb-[100px] pb-10'>
    <div className='flex flex-row items-center'>
      <AtriaIcon fill='#fff' className='md:-ml-10 -ml-5 ' />
      <span className={cn(TextLayout.callout2, 'pl-4')}>{title}</span>
    </div>
    <span className={cn(TextLayout.empashis, 'uppercase opacity-[0.6]')}>{subtitle}</span>
  </div>
);

const InfoSection = ({ title, description, buttonLabels, buttonLinks }: InfoSectionProps) => {
  const handleOpenVideoLink = useCallback((link: string) => {
    window.open(link, '_blank');
  }, []);

  return (
    <div className='grid md:grid-cols-2 border-t border-white items-start pt-4 pb-[50px] gap-[17px]'>
      <div className='flex flex-row items-center mr-[17px]'>
        <span className='h-2 w-2 bg-white rounded-full inline-block mr-4 mb-[1px]' />
        <span className={cn(TextLayout.callout3, 'pl-[22px]')}>{title}</span>
      </div>
      <div className='flex flex-col'>
        <span className={cn(TextLayout.body1, 'opacity-[0.6]')}>{description}</span>
        <div className='flex space-x-2 items-center text-white opacity-[0.6] pt-5'>
          {buttonLabels.map((label, index) => (
            <React.Fragment key={label}>
              <Button
                variant='link'
                label={label}
                color='white'
                onClick={() => handleOpenVideoLink(buttonLinks[index])}
              />
              {index < buttonLabels.length - 1 && (
                <span className='h-1 w-1 bg-white rounded-full inline-block mr-4 mb-[1px]' />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
