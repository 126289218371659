import libTailwind from '@lib-atria/ui-toolkit/dist/tailwind.config';
import { Config } from './src/config';

/** @type {import('tailwindcss').Config} */
export default {
  important: true,
  presets: [libTailwind],
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/@lib-atria/ui-toolkit/dist/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      fontFamily: {
        display: ['Ivar Display', 'sans-serif'],
        body: ['Scto Grotesk', 'sans-serif'],
      },
      colors: {
        'kelp-light': '#405a51',
        kelp: Config.newDefaultColor ? '#344740' : '#3c4e3d',
        'kelp-dark': Config.newDefaultColor ? '#2D3F39' : '#334534',
        fern: Config.newDefaultColor ? '#344740' : '#607663',
        'neue-kelp': {
          100: '#98B2A8',
          200: '#577A6D',
          300: '#42685A',
          400: '#344740',
          500: '#2D3F39',
          600: '#273933',
        },
        rust: '#a25b4c',
        stone: '#f2eee2',
        dust: '#e7d4c6',
        blush: '#c99287',
        'off-black': '#231F20',
        navy: '#2b3955',
        'experimental-forest': '#3c4e3d',
        'off-white': '#f8f7f5',
        cream: '#C4BBB2',
        'content-emphasis': '#090909',
        'border-regular': '#C7C7C7',
        'product-moss': Config.newDefaultColor ? '#72988A' : '#506653',
        'border-subtle': '#E3E3E3',
        'product-gray': {
          100: '#B8B8B8',
          200: '#E8E8E8',
          300: '#B8B8B8',
          400: '#757575',
          500: '#646464',
        },
        'product-forest': {
          100: Config.newDefaultColor ? '#344740' : '#3C4E3D',
          200: Config.newDefaultColor ? '#273933' : '#334534',
          300: Config.newDefaultColor ? '#42685A' : '#2D3C2D',
        },
        'product-sand': {
          100: '#F8F7F5',
          200: '#F4F1EE',
          300: '#E7E4DF',
          400: '#DCD8D0',
          500: '#CBC6BD',
        },
        'product-sage': Config.newDefaultColor ? '#72988A' : '#869D89',
        protection: {
          200: 'rgba(100, 100, 100, 0.2)',
        },
      },
      boxShadow: {
        shadow: '0px 11px 17.8px 0px rgba(218, 218, 218, 0.24)',
      },
      borderRadius: {
        '4xl': '2rem',
      },
      backgroundImage: {
        'athena-logo': 'url(/src/assets/img/athena-logo-white.png)',
        'atria-logo-word-fern': 'url(/src/assets/img/atria-word-fern.svg)',
        'sign-in-background': 'url(/src/assets/img/sign-in-background.jpeg)',
        'sign-out-background': 'url(/src/assets/img/sign-out-background.jpeg)',
        noise: `url(${Config.StaticResourcesBucketURL}/images/noise-texture.jpg)`,
        'noise-sand': `url(${Config.StaticResourcesBucketURL}/images/noise-sand.svg)`,
        'noise-fern': `url(${Config.StaticResourcesBucketURL}/images/noise-fern.svg)`,
        'noise-white': `url(${Config.StaticResourcesBucketURL}/images/noise-white.svg)`,
        'noise-forest-100': `url(${Config.StaticResourcesBucketURL}/images/noise-forest-100.svg)`,
        'stone-horizontal-1': `url(${Config.StaticResourcesBucketURL}/images/stones/stone-horizontal-1.webp)`,
      },
      display: ['group-hover'],
    },
  },
  plugins: [],
};
