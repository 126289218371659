import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { InputText } from 'primereact/inputtext';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  SearchScorecardByPatient,
  searchScorecardByPatientResolver,
} from './searchScorecardByPatientIdSchema';

type Params = {
  onGenerate: (patientId: number) => void;
};

export const SearchScorecardByPatientId = ({ onGenerate }: Params) => {
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<SearchScorecardByPatient>({ resolver: searchScorecardByPatientResolver });

  const isButtonDisabled = useMemo(() => {
    return !dirtyFields.patientId || !!errors.patientId?.message?.length;
  }, [dirtyFields.patientId, errors.patientId?.message?.length]);

  const handleGenerate = useCallback(
    (data: SearchScorecardByPatient) => {
      onGenerate(data.patientId);
    },
    [onGenerate]
  );

  return (
    <div className='w-full flex flex-col gap-4'>
      <form
        className='w-full flex items-start justify-start gap-2'
        onSubmit={handleSubmit(handleGenerate)}
      >
        <div className='flex flex-col flex-1'>
          <InputText
            {...register('patientId')}
            placeholder='Patient ID'
            className='flex-1 w-full rounded-[42px] outline-none px-9 py-5'
          />
          {errors.patientId?.message && (
            <p className={cn(TextLayout.body3, 'text-rust pl-5 py-2')}>
              {errors.patientId.message}
            </p>
          )}
        </div>
        <Button
          type='submit'
          variant='secondary'
          icon='search'
          size='large'
          iconVisibility='mobile-only'
          textVisibility='desktop-only'
          label='Generate'
          disabled={isButtonDisabled}
        />
      </form>
    </div>
  );
};
