import { SearchScorecardByPatientId } from '@/components';
import { ScoreCardSkeletonLoader } from '@/components/scorecard/scoreCardSkeleton';
import { ScorecardTable } from '@/components/scorecard/scorecardTable';
import { AnimationHelper } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useScoreCardPage } from './useScoreCardPage';

export function ScorecardPage() {
  const {
    isLoading,
    allAnalytesAthenaCards,
    allAnalytesGraphQLCards,
    bostonGraphQLCards,
    bostonAthenaCards,
    generateScoreCard,
    cbcAthenaCards,
    cbcGraphQLCards,
    cmpAthenaCards,
    cmpGraphQLCards,
  } = useScoreCardPage();

  const isNotEmpty = useMemo(
    () =>
      allAnalytesAthenaCards?.length ||
      allAnalytesGraphQLCards?.length ||
      bostonGraphQLCards?.length ||
      bostonAthenaCards?.length ||
      cbcGraphQLCards?.length ||
      cbcAthenaCards?.length ||
      cmpGraphQLCards?.length ||
      cmpAthenaCards?.length,
    [
      allAnalytesAthenaCards,
      allAnalytesGraphQLCards,
      bostonGraphQLCards,
      bostonAthenaCards,
      cbcGraphQLCards,
      cbcAthenaCards,
      cmpGraphQLCards,
      cmpAthenaCards,
    ]
  );

  return (
    <>
      <div className='w-full max-w-xl flex flex-col items-stretch justify-center gap-4 mb-8'>
        <SearchScorecardByPatientId onGenerate={generateScoreCard} />
      </div>

      <AnimatePresence>
        {isLoading ? (
          <ScoreCardSkeletonLoader />
        ) : (
          isNotEmpty && (
            <motion.div
              initial={{ translateY: -20, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1, transition: { duration: 0.6 } }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6, ease: AnimationHelper.ease }}
            >
              {cbcGraphQLCards && (
                <ScorecardTable
                  title='CBC'
                  description='The data below comes from the Data API (GraphQL)'
                  cards={cbcGraphQLCards}
                />
              )}
              {cbcAthenaCards && (
                <ScorecardTable
                  title='CBC'
                  description='The data below comes from the Athena API'
                  cards={cbcAthenaCards}
                />
              )}
              {cmpGraphQLCards && (
                <ScorecardTable
                  title='CMP'
                  description='The data below comes from the Data API (GraphQL)'
                  cards={cmpGraphQLCards}
                />
              )}
              {cmpAthenaCards && (
                <ScorecardTable
                  title='CMP'
                  description='The data below comes from the Athena API'
                  cards={cmpAthenaCards}
                />
              )}
              {allAnalytesAthenaCards && (
                <ScorecardTable
                  title='All Analytes'
                  description='The data below comes from the Athena API'
                  cards={allAnalytesAthenaCards}
                />
              )}
              {allAnalytesGraphQLCards && (
                <ScorecardTable
                  title='All Analytes'
                  description='The data below comes from the Data API (GraphQL)'
                  cards={allAnalytesGraphQLCards}
                />
              )}
              {bostonAthenaCards && (
                <ScorecardTable
                  title='Boston Heart Diagnostics'
                  description='The data below comes from the Athena API'
                  cards={bostonAthenaCards}
                />
              )}
              {bostonGraphQLCards && (
                <ScorecardTable
                  title='Boston Heart Diagnostics'
                  description='The data below comes from the Data API (GraphQL)'
                  cards={bostonGraphQLCards}
                />
              )}
            </motion.div>
          )
        )}
      </AnimatePresence>
    </>
  );
}
