import { AllDoctors, Doctor, Provider } from '@/@types';
import { useAuthContext, useLoaderContext } from '@/contexts';
import { CmsService, patientsService } from '@/services';
import { useCallback } from 'react';

export function useCareTeam() {
  const { startLoader, stopLoader } = useLoaderContext();
  const { patient } = useAuthContext();

  const findDoctor = useCallback(async (id: string): Promise<Doctor | null> => {
    try {
      const { data } = await CmsService.findDoctor(id);
      return data;
    } catch (error) {
      return null;
    }
  }, []);

  const findAllDoctors = useCallback(async (): Promise<AllDoctors | null> => {
    try {
      startLoader();
      const { data } = await CmsService.findAllDoctors();
      return data;
    } catch (error) {
      return null;
    } finally {
      stopLoader();
    }
  }, [startLoader, stopLoader]);

  const findSpecialistsByPatient = useCallback(async (): Promise<Provider | null> => {
    try {
      const { data } = await patientsService.findSpecialistsByPatient(patient!.id);
      return data;
    } catch (error) {
      return null;
    }
  }, [patient]);

  return {
    findDoctor,
    findAllDoctors,
    findSpecialistsByPatient,
  };
}
