import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  className?: string;
};

export function ScoreCardEmptyCell({ className }: Props) {
  return (
    <div className={cn('flex items-center justify-start py-2 pl-2 w-full', className)}>
      <p className={cn(TextLayout.body1, 'text-gray-500 opacity-50')}>-</p>
    </div>
  );
}
