import { MemberPortal } from '@/@types';
import {
  cn,
  CollapseTable,
  CollapseTableFilter,
  CollapseTableItem,
  TextLayout,
} from '@lib-atria/ui-toolkit';
import { useCallback, useMemo, useState } from 'react';
import { useScoreCardTable } from './useScoreCardTable';

type Props = {
  title?: string;
  description?: string;
  cards: MemberPortal.Scorecard.AnalytesCard[];
};

export function ScorecardTable({ cards, title, description }: Props) {
  const { tableFilters, getHeaders, separators } = useScoreCardTable();
  const [itemsFiltered, setItemsFiltered] = useState<
    MemberPortal.Scorecard.AnalytesCardItemsTagged['items']
  >([]);

  const allItems = useMemo(() => cards.flatMap((c) => c.items), [cards]);

  const handleOnItemsFilter = useCallback((itemsList: CollapseTableItem[][]) => {
    setItemsFiltered(itemsList);
  }, []);

  return (
    <div className='w-full mt-8'>
      {title && (
        <div className='my-7 pt-7 border-t border-kelp'>
          <h1 className={cn(TextLayout.h1.scto, 'text-kelp w-full md:max-w-[50%]')}>{title}</h1>
          {description && (
            <div className='w-full flex flex-row justify-end'>
              <p className='w-full md:max-w-[50%]'>{description}</p>
            </div>
          )}
        </div>
      )}
      <CollapseTableFilter
        filters={tableFilters}
        className='mb-4 px-4'
        items={allItems}
        onFilteredItemsChange={handleOnItemsFilter}
      />
      <div className='space-y-6'>
        {cards.map((card) => (
          <CollapseTable
            key={card.tag}
            title={card.title}
            items={itemsFiltered.filter((i) => i.some((item: any) => item.__tag__ === card.tag))}
            separators={separators}
            headers={getHeaders(card.headers)}
            expandableConfig={{ key: 'expandableContent', contentIndex: 0 }}
          />
        ))}
      </div>
    </div>
  );
}
