import { PatientDocument } from '@/@types';
import { useLoaderContext } from '@/contexts';
import { AttachmentHelper } from '@/helper';
import { patientsService } from '@/services';
import axios from 'axios';
import { useCallback } from 'react';

export function usePatientDocuments() {
  const { startLoader, stopLoader } = useLoaderContext();

  const deletePatientDocument = useCallback(
    async (patientId: number, documentId: number) => {
      try {
        startLoader();
        await patientsService.deletePatientDocumentAttachment(patientId, documentId);
        await patientsService.deletePatientDocument(patientId, documentId);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findPatientDocuments = useCallback(
    async (patientId: number): Promise<PatientDocument[]> => {
      try {
        startLoader();
        const { data } = await patientsService.findPatientDocuments(patientId);
        return data;
      } catch {
        return [];
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findPatientDocument = useCallback(
    async (patientId: number, documentId: number): Promise<PatientDocument | null> => {
      try {
        startLoader();
        const { data } = await patientsService.findPatientDocument(patientId, documentId);
        return data;
      } catch {
        return null;
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findPatientDocumentAttachment = useCallback(
    async (patientId: number, documentId: number, fileName: string, isAthenaDocument?: boolean) => {
      const { data, headers } = await patientsService.findPatientDocumentAttachment(
        patientId,
        documentId,
        fileName,
        isAthenaDocument
      );

      if (data.byteLength === 0) {
        return;
      }
      return AttachmentHelper.parseContentType(data, headers['content-type']);
    },
    []
  );

  const uploadNewPatientDocument = useCallback(
    async (patientId: number, filename: string, file: File, date: string, favorite: boolean) => {
      startLoader();

      const fileName = `${filename}.${file.name.split('.').pop()}`;

      try {
        const form = new FormData();
        form.append('file', file);
        const {
          data: { googleUrl, fileUrl },
        } = await patientsService.generateSignedUrl(patientId, fileName);

        await axios.put(googleUrl, form, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        const patientDocument = await patientsService.createPatientDocument(
          patientId,
          filename,
          fileUrl,
          date,
          favorite
        );

        return patientDocument;
      } catch {
        throw new Error('File upload failed');
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const uploadEditedPatientDocument = useCallback(
    async (
      patientId: number,
      documentId: number,
      documentName: string,
      date: string,
      documentUrl: string,
      favorite: boolean,
      file?: File
    ) => {
      startLoader();

      let url = documentUrl;

      try {
        if (file) {
          await patientsService.deletePatientDocumentAttachment(patientId, documentId);

          const fileName = `${documentName}.${file.name.split('.').pop()}`;
          const form = new FormData();
          form.append('file', file);

          const {
            data: { googleUrl, fileUrl },
          } = await patientsService.generateSignedUrl(patientId, fileName);

          url = fileUrl;

          await axios.put(googleUrl, form, {
            headers: {
              'Content-Type': 'application/pdf',
            },
          });
        }

        const patientDocument = await patientsService.updatePatientDocument(
          patientId,
          documentId,
          documentName,
          url,
          date,
          favorite
        );

        return patientDocument;
      } catch {
        throw new Error('File upload failed');
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  return {
    deletePatientDocument,
    findPatientDocument,
    findPatientDocumentAttachment,
    findPatientDocuments,
    uploadNewPatientDocument,
    uploadEditedPatientDocument,
  };
}
