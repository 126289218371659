import { useContactDialogContext } from '@/contexts';
import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';

type Props = {
  title: string;
  description: string;
};

export function ScorecardExpandedContent({ description, title }: Props) {
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();
  const openAskQuestionModal = useCallback(() => {
    setContactDialogTopic(`Question about ${title} results`);
    setContactDialogVisibility(true);
  }, [setContactDialogTopic, setContactDialogVisibility, title]);

  return (
    <div className='flex flex-col max-w-[485px] pointer-events-auto'>
      <p className={cn(TextLayout.body2, 'text-product-gray-500')}>{description}</p>
      <div className='mt-10'>
        <Button
          variant='secondary'
          onClick={openAskQuestionModal}
          size='medium'
          label='Ask a question'
        />
      </div>
    </div>
  );
}
