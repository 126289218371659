import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth, SidebarType } from '@/@types';
import { env } from '@/constants';
import { useAuthContext } from '@/contexts';
import { Sidebar } from './sidebar';

const items: SidebarType.Item[] = [
  {
    label: 'Access as a patient',
    icon: { name: 'login' },
    url: '/admin',
  },
  {
    label: 'Members',
    icon: { name: 'people' },
    url: '/admin/members',
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_ADMIN_PATIENTS_PAGE,
  },
  {
    label: 'Users',
    icon: { name: 'people' },
    url: '/admin/users',
  },
  {
    label: 'Scorecard',
    icon: { name: 'menu' },
    url: '/admin/scorecard',
    permission: Auth.Permissions.AdmPermissions.VIEW_SCORECARD,
  },
];

export function SidebarAdmin() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const handleOnNavigate = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  const handleOnSignOut = useCallback(() => {
    navigate('/admin/sign-out');
  }, [navigate]);

  const handleOnSwitchMember = useCallback(() => {
    navigate('/admin/switch-member');
  }, [navigate]);

  return (
    <Sidebar
      variant='gray'
      items={items}
      userName={user!.name}
      isContactButtonVisible={false}
      prefix='/admin'
      onSignOut={handleOnSignOut}
      onSwitchMember={handleOnSwitchMember}
      onNavigate={handleOnNavigate}
    />
  );
}
