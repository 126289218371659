import { LoginCallback } from '@okta/okta-react';
import { Route, useLocation } from 'react-router-dom';

import { env } from '@/constants';
import { AdminLayout } from '@/layout';
import {
  AccessAsAPatientPage,
  ArtificialIntelligencePage,
  ComponentsPage,
  OktaNotAuthorizedPage,
  PatientEditPage,
  PatientRegistrationPage,
  PatientsPage,
  ScorecardPage,
  SignOutAdminPage,
  SwitchMemberPage,
  UserEditPage,
  UsersPage,
} from '@/pages';
import { SentryRoutes } from '@/providers/sentry';
import { OktaAuthContainer } from './auth';
import { OktaAuthRoute } from './auth/oktaAuthRoute';
import { Redirect } from './redirect';

export function AdminRoutes() {
  const location = useLocation();

  return (
    <OktaAuthContainer>
      <SentryRoutes location={location} key={location.pathname}>
        <Route
          path='/sign-in/okta/callback'
          element={<LoginCallback errorComponent={OktaNotAuthorizedPage} />}
        />
        <Route path='/admin' element={<OktaAuthRoute />}>
          <Route element={<AdminLayout />}>
            <Route path='' element={<AccessAsAPatientPage />} />
            <Route path='users'>
              <Route path='' element={<UsersPage />} />
              <Route path='create' element={<PatientRegistrationPage />} />
              <Route path='edit/:id' element={<UserEditPage />} />
            </Route>
            {env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_ADMIN_PATIENTS_PAGE && (
              <Route path='members'>
                <Route path='' element={<PatientsPage />} />
                <Route path='edit/:id' element={<PatientEditPage />} />
              </Route>
            )}
            <Route path='ai' element={<ArtificialIntelligencePage />} />
            <Route path='scorecard' element={<ScorecardPage />} />
            <Route path='switch-member' element={<SwitchMemberPage />} />
            <Route path='sign-out' element={<SignOutAdminPage />} />
          </Route>
          <Route path='components' element={<ComponentsPage />} />
          <Route path='registration' element={<Redirect to='/admin/users/create' />} />
        </Route>
      </SentryRoutes>
    </OktaAuthContainer>
  );
}
