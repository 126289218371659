import { cn } from '@lib-atria/ui-toolkit';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';

type Props = {
  status: Record<string, any> | null;
  statusKey: string;
};

export function ScoreCardStatusCell({ status, statusKey }: Props) {
  if (!status) {
    return <ScoreCardEmptyCell />;
  }
  const [value] = Object.values(status);

  if (!value || !status[statusKey] || status[statusKey].length === 0) {
    return <ScoreCardEmptyCell />;
  }

  if (Array.isArray(value)) {
    return (
      <div className='flex flex-col'>
        {value.map((s, i) => (
          <div
            key={i}
            className={cn('flex rounded-full px-3 py-1 text-white capitalize', {
              'bg-off-black': s.status !== 'normal',
              'bg-neue-kelp-300': s.status === 'normal',
            })}
          >
            {s.status}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={cn('flex rounded-full px-3 py-1 text-white capitalize', {
        'bg-off-black': status[statusKey] !== 'normal',
        'bg-neue-kelp-300': status[statusKey] === 'normal',
      })}
    >
      {status[statusKey]}
    </div>
  );
}
