import { cn, CollapseTableItem, Range, TextLayout, Tooltip, Trend } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';
import { ScoreCardStatusCell } from './scoreCardStatusCell';

export function useScoreCardTable() {
  const separators = ['HISTORICAL FINDINGS'];

  const tableFilters: any[] = useMemo(
    () => [
      { type: 'select', label: 'All', validateFn: () => true },
      {
        type: 'select',
        label: 'Normal',
        validateFn: (item: CollapseTableItem) => {
          return item?.status?.toLowerCase() === 'normal';
        },
      },
      {
        type: 'select',
        label: 'Abnormal',
        validateFn: (item: CollapseTableItem) => {
          return item.status?.toLowerCase() !== 'normal';
        },
      },
      {
        type: 'text',
        label: 'Metric',
        key: 'metric',
        validateFn: (item: CollapseTableItem, value: string) => {
          if (!value) return true;
          return item?.metric?.toLowerCase() === value?.toLowerCase();
        },
      },
    ],
    []
  );

  const getAnalyteHeaders = useCallback((header: any[]) => {
    return header?.map(({ key, label }) => {
      if (key === 'atriaPreventiveRange') {
        return {
          key,
          label: (
            <Tooltip text='The Atria preventive range is the range we recommend for longevity.'>
              Atria preventive range
            </Tooltip>
          ),
          className: 'min-w-[230px]',
          renderItem: (item: any) => {
            return (
              <Range
                value={item.atriaPreventiveRange?.value}
                normal={item.atriaPreventiveRange?.referenceRange}
              />
            );
          },
        };
      }
      if (key === 'labProvidedRange') {
        return {
          key,
          label: (
            <Tooltip text='This range describes your most recent finding and shows where on the reference range it falls. The range is provided by the lab that processed your results.'>
              Lab provided range
            </Tooltip>
          ),
          className: 'min-w-[230px]',
          renderItem: (item: any) => {
            return (
              <Range
                value={item.labProvidedRange?.value}
                normal={item.labProvidedRange?.referenceRange}
              />
            );
          },
        };
      }
      if (key === 'recentFinding') {
        return {
          key,
          label: 'Recent Finding',
          renderItem: (item: any) => {
            return (
              <div className='flex items-center gap-1'>
                <p className={cn(TextLayout.body1, 'text-neue-kelp-400')}>
                  {item.recentFinding?.value}
                </p>
                <span className='text-[11px] text-gray-500'>{item.recentFinding?.units}</span>
              </div>
            );
          },
        };
      }
      if (key === 'status') {
        return {
          key,
          label: (
            <Tooltip text='The status of your historical finding. Note that this status comes from the lab and may be related to a different reference range from the one you saw on the left.'>
              Status
            </Tooltip>
          ),
          renderItem: (item: any, itemKey: string) => {
            return <ScoreCardStatusCell status={item} statusKey={itemKey} />;
          },
        };
      }
      if (key === 'trend') {
        return {
          key,
          label: (
            <Tooltip text='This trend shows whether your most recent finding is higher or lower than the previous finding.'>
              Trend
            </Tooltip>
          ),
          className: 'min-w-[110px] [&>*:first-child]:justify-center',
          renderItem: (item: any) => {
            if (!item.trend) {
              return <ScoreCardEmptyCell className='justify-center' />;
            }
            return (
              <div className='w-full flex flex-col items-center'>
                <Trend direction={item.trend?.toLowerCase()} />
              </div>
            );
          },
        };
      }
      return { key, label };
    });
  }, []);

  const getHistoricalHeaders = useCallback((headers: any[]) => {
    return headers?.map(({ key, label }) => {
      if (label !== 'Status') {
        return {
          key,
          label,
          renderItem: (item: any, itemKey: string) => {
            const value = item[itemKey];
            if (!value) {
              return <ScoreCardEmptyCell />;
            }
            return renderItem(item, itemKey);
          },
        };
      }
      return {
        key,
        label,
        renderItem: (item: any, itemKey: string) => {
          return <ScoreCardStatusCell status={item} statusKey={itemKey} />;
        },
      };
    });
  }, []);

  const renderItem = (items: any, itemKey: string) => {
    const value = items[itemKey];
    if (Array.isArray(value)) {
      return (
        <div>
          {value.map((item, index) => (
            <div key={index} className='mb-4'>
              <p className='text-[11px] text-gray-500'>
                {DateTime.fromISO(item.date).toFormat('LLL d')}
              </p>
              <div className='flex items-baseline justify-between space-x-1'>
                <span className={cn(TextLayout.body1)}>{item.value}</span>
                <span className='text-[11px] text-gray-500'>{item.units}</span>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className='flex align-bottom gap-1'>
          <p className={cn(TextLayout.body1, 'text-neue-kelp-400')}>{value?.value}</p>
          <span className='text-[11px] text-gray-500'>{value?.units}</span>
        </div>
      );
    }
  };

  const getHeaders = useCallback(
    (headers: any[][]) => {
      return headers?.map((header, headerIndex) => {
        if (headerIndex === 0) {
          return getAnalyteHeaders(header);
        }
        return getHistoricalHeaders(header);
      });
    },
    [getAnalyteHeaders, getHistoricalHeaders]
  );

  return {
    separators,
    tableFilters,
    getHeaders,
  };
}
