type Props = {
  type: 'out-of-range' | 'in-range';
};

export function RangeIcon({ type }: Props) {
  return type === 'in-range' ? (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_3180_217127)'>
        <circle cx='12.5' cy='10.5' r='5.5' fill='url(#paint0_linear_3180_217127)' />
        <circle cx='12.5' cy='10.5' r='5' stroke='url(#paint1_linear_3180_217127)' />
      </g>
      <defs>
        <filter
          id='filter0_d_3180_217127'
          x='0.6'
          y='0.0999999'
          width='23.8'
          height='23.8'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1.5' />
          <feGaussianBlur stdDeviation='3.2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.309735 0 0 0 0 0.901774 0 0 0 0 0.674067 0 0 0 0.8 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3180_217127' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3180_217127'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_3180_217127'
          x1='18'
          y1='16'
          x2='4.73196'
          y2='10.1031'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#228C65' />
          <stop offset='1' stopColor='#67CEA8' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3180_217127'
          x1='7'
          y1='5'
          x2='19.3937'
          y2='6.89772'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#75E6BB' />
          <stop offset='1' stopColor='#3D9272' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_3180_217135)'>
        <circle cx='13.5' cy='11.5' r='5.5' fill='url(#paint0_linear_3180_217135)' />
        <circle cx='13.5' cy='11.5' r='5' stroke='url(#paint1_linear_3180_217135)' />
      </g>
      <defs>
        <filter
          id='filter0_d_3180_217135'
          x='0.6'
          y='0.0999999'
          width='25.8'
          height='25.8'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1.5' />
          <feGaussianBlur stdDeviation='3.7' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.865706 0 0 0 0 0.470952 0 0 0 0 0.387553 0 0 0 0.6 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3180_217135' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3180_217135'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_3180_217135'
          x1='19'
          y1='17'
          x2='5.73196'
          y2='11.1031'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A25B4C' />
          <stop offset='1' stopColor='#C99287' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3180_217135'
          x1='8'
          y1='6'
          x2='20.3937'
          y2='7.89772'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7D4C6' />
          <stop offset='1' stopColor='#A25B4C' />
        </linearGradient>
      </defs>
    </svg>
  );
}
