import { PageTitle } from '@/components';
import { ScorecardSummary } from '@/components/scorecard/scorecardSummary';
import { usePageContext } from '@/contexts';
import { useEffect } from 'react';

export function PatientScorecardPage() {
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();

  useEffect(() => {
    setPageTitle('Results');
    setIsBackButtonVisible(false);
  }, [setIsBackButtonVisible, setPageTitle]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Scorecard' />
      <ScorecardSummary />
    </div>
  );
}
