import { CircleIcon, cn } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { TextLayout } from '../text';

export function PastAppointmentsCard({ groupItem }: any) {
  const dayOfWeek = useMemo(() => {
    return DateTime.fromISO(groupItem.date).toFormat('cccc');
  }, [groupItem.date]);

  const date = useMemo(() => {
    return DateTime.fromISO(groupItem.date).toFormat('LLL dd, y');
  }, [groupItem.date]);

  return (
    <div className='flex flex-col md:flex-row gap-3'>
      <div className='rounded-[10px] pl-[26px] pr-[25px] pt-10 bg-product-sand-100 w-full md:min-h-[520px] h-full'>
        <p className={cn(TextLayout.callout3, 'text-neue-kelp-400 mb-3')}>{dayOfWeek}</p>
        <p className={cn(TextLayout.h1.scto, 'text-neue-kelp-400')}>{date}</p>
      </div>
      <div className='rounded-[10px] p-8 bg-product-sand-100 w-full'>
        <div className='flex w-full flex-col '>
          <h1 className={cn(TextLayout.callout3, 'text-product-forest-100')}>
            Led by Dr. Jahangir Rahman
          </h1>
          <p className={cn(TextLayout.callout3, 'text-product-gray-400')}>
            {groupItem.location || 'Schedule'}
          </p>
          <ul className='p-4'>
            {groupItem.appointments.map((appointment: any) => (
              <li
                key={`appointment-schedule-${groupItem.key}-${appointment.id}`}
                className='flex w-full flex-row items-center justify-between py-2.5'
              >
                <div className='flex items-center'>
                  <CircleIcon />
                  <p className={cn(TextLayout.body2, 'text-product-gray-400')}>
                    {appointment.title}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
