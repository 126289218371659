import ContentLoader from 'react-content-loader';

export function ScoreCardSkeletonLoader() {
  return (
    <ContentLoader
      speed={2}
      width='100%'
      height='100%'
      viewBox='0 0 844 600'
      backgroundColor='#ccc'
      foregroundColor='#ddd'
    >
      <rect x='0' y='0' rx='20' ry='20' width='6%' height='36' />
      <rect x='7%' y='0' rx='20' ry='20' width='12%' height='36' />
      <rect x='20%' y='0' rx='20' ry='25' width='12%' height='36' />
      <rect x='94%' y='0' rx='20' ry='25' width='6%' height='36' />

      <rect x='0' y='50' rx='10' ry='10' width='100%' height='400' />
    </ContentLoader>
  );
}
