import { useLoaderContext } from '@/contexts';
import { patientsService } from '@/services';
import { useCallback } from 'react';

export function useMedications() {
  const { startLoader, stopLoader } = useLoaderContext();

  const findPatientsMedicationsList = useCallback(
    async (patientId: number) => {
      try {
        startLoader();
        const { data } = await patientsService.findPatientsMedicationsList(patientId);
        return data;
      } catch {
        return null;
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  return {
    findPatientsMedicationsList,
  };
}
