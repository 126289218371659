import { MemberPortal } from '@/@types';
import { ScorecardExpandedContent } from '@/components/scorecard/scorecardExpandedContent';
import { usePageContext } from '@/contexts';
import { usePatients } from '@/hooks';
import { useCallback, useEffect, useState } from 'react';

type ScoreCardItem = MemberPortal.Scorecard.AnalytesCardItemsTagged[];

export function useScoreCardPage() {
  const { setPageTitle } = usePageContext();
  const {
    findPatientScorecardAthena,
    findPatientScorecardGraphQL,
    findPatientBostonScorecardGraphQL,
    findPatientBostonScorecardAthena,
    findPatientCBCScorecard,
    findPatientCBCScorecardAthena,
    findPatientCMPScorecard,
    findPatientCMPScorecardAthena,
  } = usePatients();
  const [isLoading, setIsLoading] = useState(false);
  const [allAnalytesAthenaCards, setAllAnalytesAthenaCards] = useState<ScoreCardItem | null>(null);
  const [allAnalytesGraphQLCards, setAllAnalytesGraphQLCards] = useState<ScoreCardItem | null>(
    null
  );
  const [bostonGraphQLCards, setBostonGraphQLCards] = useState<ScoreCardItem | null>(null);
  const [bostonAthenaCards, setBostonAthenaCards] = useState<ScoreCardItem | null>(null);
  const [cbcGraphQLCards, setCBCGraphQLCards] = useState<ScoreCardItem | null>(null);
  const [cbcAthenaCards, setCBCAthenaCards] = useState<ScoreCardItem | null>(null);
  const [cmpGraphQLCards, setCMPGraphQLCards] = useState<ScoreCardItem | null>(null);
  const [cmpAthenaCards, setCMPAthenaCards] = useState<ScoreCardItem | null>(null);

  const addingTagsToItems = useCallback((cardItem: any, headers: any[]) => {
    const items = cardItem.items || [];
    const processedItems = items
      .filter((itemByHeader: any[]) => itemByHeader.length > 0)
      .map((itemByHeader: any) =>
        itemByHeader.map((item: any, index: number) => {
          if (index === 0) {
            item.expandableContent = (
              <ScorecardExpandedContent description={item.description} title={item.metric} />
            );
          }

          return {
            ...item,
            __tag__: cardItem.tag,
          };
        })
      );

    return {
      ...cardItem,
      headers,
      items: processedItems,
    };
  }, []);

  const generateScoreCard = useCallback(
    async (patientId: number) => {
      setIsLoading(true);
      const [
        allAnalytesAthena,
        allAnalytesGraphQL,
        bostonGraphQL,
        bostonAthena,
        cbcGraphQL,
        cbcAthena,
        cmpGraphQL,
        cmpAthena,
      ] = await Promise.all([
        findPatientScorecardAthena(patientId),
        findPatientScorecardGraphQL(patientId),
        findPatientBostonScorecardGraphQL(patientId),
        findPatientBostonScorecardAthena(patientId),
        findPatientCBCScorecard(patientId),
        findPatientCBCScorecardAthena(patientId),
        findPatientCMPScorecard(patientId),
        findPatientCMPScorecardAthena(patientId),
      ]);

      if (allAnalytesAthena) {
        const cardsList = allAnalytesAthena.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, allAnalytesAthena.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setAllAnalytesAthenaCards(cardsList);
      }

      if (allAnalytesGraphQL) {
        const cardsList = allAnalytesGraphQL.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, allAnalytesGraphQL.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setAllAnalytesGraphQLCards(cardsList);
      }

      if (bostonGraphQL) {
        const cardsList = bostonGraphQL.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, bostonGraphQL.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setBostonGraphQLCards(cardsList);
      }

      if (bostonAthena) {
        const cardsList = bostonAthena.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, bostonAthena.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setBostonAthenaCards(cardsList);
      }

      if (cbcGraphQL) {
        const cbcList = cbcGraphQL.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, cbcGraphQL.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setCBCGraphQLCards(cbcList);
      }

      if (cbcAthena) {
        const cbcList = cbcAthena.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, cbcAthena.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setCBCAthenaCards(cbcList);
      }

      if (cmpGraphQL) {
        const cmpList = cmpGraphQL.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, cmpGraphQL.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setCMPGraphQLCards(cmpList);
      }

      if (cmpAthena) {
        const cmpList = cmpAthena.group.reduce<ScoreCardItem>((acc, cardItem) => {
          const tags = addingTagsToItems(cardItem, cmpAthena.headers);
          if (tags.items.length > 0) {
            acc.push(tags);
          }
          return acc;
        }, []);
        setCMPAthenaCards(cmpList);
      }
      setIsLoading(false);
    },
    [
      addingTagsToItems,
      findPatientBostonScorecardAthena,
      findPatientBostonScorecardGraphQL,
      findPatientCBCScorecard,
      findPatientCBCScorecardAthena,
      findPatientCMPScorecard,
      findPatientCMPScorecardAthena,
      findPatientScorecardAthena,
      findPatientScorecardGraphQL,
    ]
  );

  useEffect(() => {
    setPageTitle('Scorecard');
  }, [setPageTitle]);

  return {
    isLoading,
    allAnalytesAthenaCards,
    allAnalytesGraphQLCards,
    generateScoreCard,
    bostonGraphQLCards,
    bostonAthenaCards,
    cbcGraphQLCards,
    cbcAthenaCards,
    cmpGraphQLCards,
    cmpAthenaCards,
  };
}
