import { Auth } from '@/@types';
import { PageTitle, PatientDocumentsCard, UploadDocumentSidebar } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function DocumentsPage() {
  const { setPageTitle } = usePageContext();
  const { isAdmin, user } = useAuthContext();
  const { documents, getDocuments } = useApplicationContext();
  const [isUploadSidebarVisible, setIsUploadSidebarVisible] = useState(false);

  const handleDocumentUpload = useCallback(() => {
    setIsUploadSidebarVisible(false);
    getDocuments();
  }, [getDocuments]);

  const canUploadDocument = useMemo(() => {
    return (
      isAdmin && !!user?.permissions?.includes(Auth.Permissions.AdmPermissions.MANAGE_DOCUMENTS)
    );
  }, [isAdmin, user?.permissions]);

  useEffect(() => {
    setPageTitle('Documents');
    eventAnalytics.track(TRACK_EVENTS.DOCUMENTS_PAGE_VIEWED);
  }, [setPageTitle]);

  useEffect(() => {
    if (!documents) {
      getDocuments();
    }
  }, [documents, getDocuments]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      {canUploadDocument && (
        <>
          <div className='flex flex-col sm:flex-row justify-between align-middle'>
            <PageTitle title='Your latest results' />
            <div className='md:min-w-[300px] flex sm:justify-end align-middle'>
              <Button
                className='md:mt-5'
                label='Upload new document'
                icon='pi pi-file-import'
                size='small'
                onClick={() => setIsUploadSidebarVisible(true)}
              />
            </div>
          </div>
          <UploadDocumentSidebar
            visible={isUploadSidebarVisible}
            handleOnHide={() => setIsUploadSidebarVisible(false)}
            handleDocumentUpload={handleDocumentUpload}
          />
        </>
      )}
      <>
        <PatientDocumentsCard documents={documents || []} />
      </>
    </div>
  );
}
