import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Resource } from '@/@types';
import { BasicCard, CustomPdfViewer, Loading, UploadResourceSidebar } from '@/components';
import { useAuthContext, usePageContext, useToastContext } from '@/contexts';
import { DateHelper } from '@/helper';
import { useResources } from '@/hooks';

export function ResourceDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isStaffAdmin } = useAuthContext();
  const { toast } = useToastContext();
  const { setPageTitle } = usePageContext();
  const { findResource, findResourceAttachment, deleteResource } = useResources();
  const [resource, setResource] = useState<Resource>({} as Resource);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentData, setAttachmentData] = useState<{ src: string; type: string }>();
  const [isUploadSidebarVisible, setIsUploadSidebarVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const findAttachment = useCallback(
    async (resourceData: Resource) => {
      const attachment = await findResourceAttachment(resourceData.id);
      setAttachmentData(attachment);
    },
    [findResourceAttachment]
  );

  const loadInitialData = useCallback(async () => {
    if (id) {
      const resourceData = await findResource(Number(id));
      if (!resourceData) return;

      setResource(resourceData);

      try {
        setIsAttachmentLoading(true);
        await findAttachment(resourceData);
      } finally {
        setIsAttachmentLoading(false);
      }
    }
  }, [findAttachment, findResource, id]);

  const handleResourceUpload = useCallback(() => {
    loadInitialData();
    setIsUploadSidebarVisible(false);
  }, [loadInitialData]);

  const handleResourceDelete = useCallback(async () => {
    if (id) {
      try {
        await deleteResource(Number(id));
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Resource deleted successfully',
          life: 2500,
        });
        navigate('/resources');
      } catch {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Resource delete failed',
          life: 2500,
        });
      }
    }
    setIsDeleteDialogVisible(false);
  }, [deleteResource, id, navigate, toast]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  useEffect(() => {
    setPageTitle('Resources');
  }, [setPageTitle]);

  return (
    <div className='mt-5'>
      <a className='md:px-[66px] items-center flex' href='#' onClick={() => navigate(-1)}>
        <i className='pi pi-chevron-left mr-1'></i>
        <span className='hover:underline'>Back</span>
      </a>
      {resource && (
        <main>
          <BasicCard title={resource.resourceName}>
            {isAttachmentLoading ? (
              <Loading label='Loading attachments...' />
            ) : attachmentData?.src ? (
              <div className='h-full flex flex-col'>
                {resource.createdAt && (
                  <div className='flex'>
                    {DateHelper.formatDateToDisplay(DateTime.fromISO(resource.createdAt))}
                  </div>
                )}

                {isStaffAdmin && (
                  <div className='flex flex-col my-5 md:flex-row'>
                    <div>
                      <Button
                        label='Edit resource'
                        icon='pi pi-file-import'
                        size='small'
                        className='w-[100%] md:justify-self-start'
                        outlined
                        onClick={() => setIsUploadSidebarVisible(true)}
                      />
                    </div>
                    <div>
                      <Button
                        label='Delete resource'
                        icon='pi pi-file-import'
                        size='small'
                        className='w-[100%] mt-5 md:mt-0 md:ml-5 md:justify-self-start'
                        outlined
                        severity='danger'
                        onClick={() => setIsDeleteDialogVisible(true)}
                      />
                    </div>
                    <ConfirmDialog
                      draggable={false}
                      visible={isDeleteDialogVisible}
                      onHide={() => setIsDeleteDialogVisible(false)}
                      message='Are you sure you want to delete this resource?'
                      header='Delete resource'
                      icon='pi pi-exclamation-triangle'
                      accept={handleResourceDelete}
                      reject={() => setIsDeleteDialogVisible(false)}
                    />

                    <UploadResourceSidebar
                      visible={isUploadSidebarVisible}
                      handleOnHide={() => setIsUploadSidebarVisible(false)}
                      handleDocumentUpload={handleResourceUpload}
                      data={resource}
                      isEditing={true}
                    />
                  </div>
                )}

                <CustomPdfViewer fileUrl={attachmentData.src} />
              </div>
            ) : (
              <span>No attachments found</span>
            )}
          </BasicCard>
        </main>
      )}
    </div>
  );
}
