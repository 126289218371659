import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { RangeIcon } from './rangeIcon';

export function ScorecardSummary() {
  return (
    <div className='flex flex-row gap-1'>
      <div className='flex w-full flex-col justify-between bg-kelp text-white rounded-lg py-6 px-8'>
        <h2 className={cn(TextLayout.h2.scto)}>Your Metrics</h2>
        <table className='w-full text-left'>
          <thead>
            <tr>
              <th className={cn(TextLayout.body1, 'w-[50%]')}>01</th>
              <th className={cn(TextLayout.body1, 'w-[50%]')}>All</th>
            </tr>
          </thead>
          <tbody className={cn(TextLayout.body1, 'text-white opacity-60')}>
            <tr>
              <td>02</td>
              <td>Blood</td>
            </tr>
            <tr>
              <td>03</td>
              <td>Brain</td>
            </tr>
            <tr>
              <td>04</td>
              <td>Cancer</td>
            </tr>
            <tr>
              <td>05</td>
              <td>Environmental</td>
            </tr>
            <tr>
              <td>06</td>
              <td>Genetic</td>
            </tr>
            <tr>
              <td>07</td>
              <td>Heart</td>
            </tr>
            <tr>
              <td>08</td>
              <td>Hormone</td>
            </tr>
            <tr>
              <td>09</td>
              <td>Immune</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Infectious diseases</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Kidneys</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Liver</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Metabolic</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Nutrients</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Pancreas</td>
            </tr>
            <tr>
              <td>16</td>
              <td>Thyroid</td>
            </tr>
            <tr>
              <td>17</td>
              <td>Other</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='w-full bg-kelp text-white rounded-lg py-6 px-8'>
        <p className='mb-16'>
          This is a summary of your lab results to date. Don&apos;t worry if some results are
          flagged as &quot;outside range&quot; —they might not be a cause for concern. Your medical
          team will let you know if there&apos;s anything to address.
        </p>
        <div className='flex flex-row gap-1 text-kelp items-end'>
          <div className='flex flex-col w-full h-[388px] bg-white justify-end items-center rounded-[4px]'>
            <span className={cn(TextLayout.callout1)}>78</span>
            <span className={cn(TextLayout.body2, 'text-product-gray-500')}>In range</span>
            <RangeIcon type='in-range' />
          </div>
          <div className='flex flex-col w-full bg-white justify-end items-center rounded-[4px] h-[206px]'>
            <span className={cn(TextLayout.callout1)}>32</span>
            <span className={cn(TextLayout.body2, 'text-product-gray-500')}>Out of range</span>
            <RangeIcon type='out-of-range' />
          </div>
        </div>
      </div>
    </div>
  );
}
