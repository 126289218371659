import { useContactDialogContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { useCallback } from 'react';
import { BasicCard, BigButton } from '..';

export function NeedAnythingCard() {
  const { setContactDialogVisibility, setContent } = useContactDialogContext();

  const handleOpenContactCareButton = useCallback(() => {
    setContactDialogVisibility(true);
    setContent(null);
    eventAnalytics.track(TRACK_EVENTS.CONTACT_CARE_BUTTON);
  }, [setContactDialogVisibility, setContent]);

  return (
    <BasicCard className='shadow-none bg-neue-kelp-300 rounded-xl px-5'>
      <p className='font-medium leading-8 text-[var(--stone)] mt-2 mb-6 grow'>
        We are here to help with any questions or concerns
      </p>
      <BigButton variant='light' onClick={handleOpenContactCareButton}>
        Contact care team
      </BigButton>
    </BasicCard>
  );
}
