import { Button, cn } from '@lib-atria/ui-toolkit';
import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Doctor } from '@/@types';
import { CareTeamAbout } from '../careTeam';
import { CareTeamAboutMobile } from '../careTeam/careTeamAboutMobile';
import { CareTeamContactTooltip } from '../careTeam/careTeamContactTooltip';
import { TextLayout } from '../text';

type Props = {
  doctor: Doctor;
};

export function CmoCard({ doctor }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [isVisibleAbout, setIsVisibleAbout] = useState(false);
  const maxLength = isMobile ? 300 : 500;

  const [firstName, lastName] = useMemo(() => {
    const parts = doctor.name.split(' ').map((part) => part.trim());
    const first = parts[0] || '';
    const last = parts.slice(1).join(' ');
    return [first, last];
  }, [doctor.name]);

  const handleAboutClick = useCallback(() => {
    setIsVisibleAbout(!isVisibleAbout);
  }, [isVisibleAbout]);

  const processDescription = useMemo(() => {
    const doctorText = doctor.shortDescription ? doctor.shortDescription : doctor.description;
    const processedText = doctorText.reduce((acc, word, index, array) => {
      const separator = index < array.length - 1 ? '<br /><br />' : '';
      return `${acc}${word}${separator}`;
    });
    return processedText;
  }, [doctor.description, doctor.shortDescription]);

  const smallText = useMemo(() => {
    return processDescription.length > maxLength
      ? `${processDescription.slice(0, maxLength)}...`
      : processDescription;
  }, [maxLength, processDescription]);

  return (
    <>
      <div className='w-full relative min-h-[550px] flex flex-col-reverse xl:flex-row flex-wrap items-stretch rounded-xl overflow-hidden'>
        <div className='bg-neue-kelp-400 flex-1 flex flex-col items-start justify-start py-7 xl:py-10 px-5 xl:px-5'>
          <div className='mb-4 md:px-5'>
            <p className={cn(TextLayout.h1.scto, 'text-white')}>{firstName}</p>
            <p className={cn(TextLayout.h1.scto, 'text-white')}>
              {lastName}
              {doctor.degree && (
                <>
                  , <span className={cn(TextLayout.callout2, 'text-base')}>{doctor.degree}</span>
                </>
              )}
            </p>
            <p
              className={cn(
                TextLayout.body1,
                'text-white max-w-full xl:max-w-[70%] *:list-disc md:mb-0 mb-[56px] mt-4'
              )}
              dangerouslySetInnerHTML={{ __html: smallText }}
            />
          </div>

          <div className='flex h-full items-end text-white  space-x-6 md:px-6'>
            <CareTeamContactTooltip doctor={doctor} />

            <Button label='About' variant='link' color='white' onClick={handleAboutClick} />
          </div>
        </div>
        <div className='flex w-full xl:max-w-[411px] flex-1'>
          <DinamicImage photo={doctor.photo} />
        </div>
      </div>
      {isMobile ? (
        <CareTeamAboutMobile
          key={doctor.id}
          doctor={doctor}
          isVisible={isVisibleAbout}
          setIsVisible={setIsVisibleAbout}
        />
      ) : (
        <CareTeamAbout
          key={doctor.id}
          doctor={doctor}
          isVisible={isVisibleAbout}
          setIsVisible={setIsVisibleAbout}
        />
      )}
    </>
  );
}

const DinamicImage = ({ photo }: { photo: string }) => {
  return (
    <>
      <div className='flex xl:hidden w-full'>
        <div
          className='flex-1 w-full min-h-[309px] md:min-h-[609px] max-h-[432px] md:max-h-fit xl:min-h-[350px] xl:max-w-[411px] xl:max-h-fit bg-cover bg-top bg-no-repeat text-white text-2xl rounded-t-xl'
          style={{
            backgroundImage: `url(${photo}?h=800)`,
          }}
        />
      </div>
      <div className='hidden xl:flex w-full flex-col'>
        <div
          className='flex-1 w-full h-[232px] max-h-[232px] md:min-h-[350px] xl:min-h-[609px] md:max-h-fit xl:max-h-fit bg-cover bg-center bg-no-repeat text-white text-2xl rounded-r-xl'
          style={{
            backgroundImage: `url(${photo}?h=500)`,
          }}
        />
      </div>
    </>
  );
};
