import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  patientId: z.preprocess(Number, z.number().int().positive()),
});

export type SearchScorecardByPatient = z.infer<typeof schema>;

export const searchScorecardByPatientResolver = zodResolver(schema);
